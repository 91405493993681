import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import axios from "axios";

const Listagem = ({ status, type }) => {
  const [loja, setLoja] = useState(null);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const accessToken = window.localStorage.getItem("accessToken");
  const tpAmb = window.localStorage.getItem("tpAmb");
  const idUser = window.localStorage.getItem("idUser");

  // Buscar informações da loja ao montar o componente
  useEffect(() => {
    if (accessToken && idUser) {
      axios
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => setLoja(response.data))
        .catch((error) => console.error("Erro ao buscar loja:", error));
    }
  }, [idUser, accessToken]);

  const fetchData = async (periodo) => {
    setIsLoading(true);

    let dataInicio, dataFim;
    const currentDate = new Date();

    switch (periodo) {
      case "hoje":
        dataInicio = currentDate.toISOString().split("T")[0];
        dataFim = dataInicio;
        break;
      case "ontem":
        const ontem = new Date();
        ontem.setDate(ontem.getDate() - 1);
        dataInicio = ontem.toISOString().split("T")[0];
        dataFim = dataInicio;
        break;
      case "semana":
        dataInicio = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()))
          .toISOString()
          .split("T")[0];
        dataFim = new Date().toISOString().split("T")[0];
        break;
      case "custom":
        dataInicio = startDate.toISOString().split("T")[0];
        dataFim = endDate.toISOString().split("T")[0];
        break;
      default:
        return;
    }

    if (loja && accessToken) {
      try {
        const response = await axios.get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipostts/${loja.id}/${dataInicio}/${dataFim}/${type}/${tpAmb}/${status}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setData(response.data?._embedded?.PDVEvento || []);

      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Relatório de Produtos", 14, 10);

    const totalProdutos = data.length;
    const totalValor = data.reduce((acc, item) => acc + parseFloat(item.valorTotalLiquido || 0), 0);

    doc.text(`Total de Produtos: ${totalProdutos}`, 14, 20);
    doc.text(`Total Valor: R$ ${totalValor.toFixed(2).replace(".", ",")}`, 14, 30);

    const tableData = data.map((item) => [
      item.numeroDocFiscal || "-",
      item.dataHoraAbertura ? new Date(item.dataHoraAbertura).toLocaleString("pt-BR") : "-",
      item.cpf || "-",
      `R$ ${parseFloat(item.valorTotalLiquido).toFixed(2).replace(".", ",")}`,
    ]);

    doc.autoTable({
      head: [["Pedido/Nota", "Data/Hora", "Consumidor", "Valor (R$)"]],
      body: tableData,
      startY: 40,
    });

    doc.save("relatorio_lista.pdf");
  };


  const totalProdutos = data.length;
  const totalValor = data.reduce((acc, item) => acc + parseFloat(item.valorTotalLiquido || 0), 0);


  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <h2>Relatório Listagem</h2>

      <div style={{ marginBottom: "20px", textAlign: "center" }}>
        {/* Botões para selecionar períodos e campos de data */}
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("hoje")}
          >
            Hoje
          </button>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("ontem")}
          >
            Ontem
          </button>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("semana")}
          >
            Semana
          </button>
          {/* Campos de data */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <strong>Início:</strong>
              <input
                type="date"
                value={startDate.toISOString().split("T")[0]}
                onChange={(e) => setStartDate(new Date(e.target.value))}
                style={{ marginLeft: "5px", padding: "5px" }}
              />
            </div>
            <div>
              <strong>Fim:</strong>
              <input
                type="date"
                value={endDate.toISOString().split("T")[0]}
                onChange={(e) => setEndDate(new Date(e.target.value))}
                style={{ marginLeft: "5px", padding: "5px" }}
              />
            </div>
            {/* Botão de Buscar */}
            <button
              style={{
                backgroundColor: loja?.id ? "black" : "grey",
                color: "#fff",
                padding: "10px 20px",
                cursor: loja?.id ? "pointer" : "not-allowed",
                border: "none",
                borderRadius: "5px",
                marginLeft: "10px",
              }}
              onClick={() => fetchData("custom")}
              disabled={!loja?.id}
            >
              Buscar
            </button>
            
          </div>
        </div>

        {/* Exibição do total de produtos e total valor lado a lado */}
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div>
            <strong>Total de Produtos:</strong> {totalProdutos}
          </div>

          <button
      style={{
        backgroundColor: "black",
        color: "#fff",
        padding: "5px 10px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      onClick={generatePDF}
    >
      Gerar PDF
    </button>


          <div>
            <strong>Total Valor:</strong> R$ {totalValor.toFixed(2).replace(".", ",")}
          </div>
        </div>


        

        {isLoading ? (
          <p style={{ textAlign: "center" }}>Carregando...</p>
        ) : (
          <div>

                {/* Botão para gerar PDF acima da tabela */}




            <div style={{ maxWidth: "100%", overflowX: "auto" }}>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                  textAlign: "left",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Pedido/Nota</th>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Data/Hora</th>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Consumidor</th>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Valor (R$)</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                        {item.numeroDocFiscal || "-"}
                      </td>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                        {item.dataHoraAbertura ? new Date(item.dataHoraAbertura).toLocaleString("pt-BR") : "-"}
                      </td>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.cpf || "-"}</td>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                        {`R$ ${parseFloat(item.valorTotalLiquido).toFixed(2).replace(".", ",")}`}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default Listagem;
