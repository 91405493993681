import React, { useState, useEffect } from "react";
import axios from "axios";
import { startOfWeek } from "date-fns";
import jsPDF from "jspdf";
import "jspdf-autotable";

const RelatorioCaixa = ({ status, type, formaPagamento }) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const tpAmb = window.localStorage.getItem("tpAmb");
  const idUser = window.localStorage.getItem("idUser");

  const [loja, setLoja] = useState(null);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (accessToken && idUser) {
      axios
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => setLoja(response.data))
        .catch((error) => console.error("Erro ao buscar loja:", error));
    }
  }, [idUser, accessToken]);

  const fetchData = async (periodo) => {
    setIsLoading(true);
  
    let dataInicio;
    let dataFim;
  
    const currentDate = new Date();
  
    switch (periodo) {
      case "hoje":
        dataInicio = currentDate.toISOString().split("T")[0];
        dataFim = dataInicio;
        break;
      case "ontem":
        const ontem = new Date();
        ontem.setDate(ontem.getDate() - 1);
        dataInicio = ontem.toISOString().split("T")[0];
        dataFim = dataInicio;
        break;
      case "semana":
        dataInicio = startOfWeek(currentDate).toISOString().split("T")[0];
        dataFim = currentDate.toISOString().split("T")[0];
        break;
      case "custom":
        dataInicio = startDate.toISOString().split("T")[0];
        dataFim = endDate.toISOString().split("T")[0];
        break;
      default:
        break;
    }
  
    setStartDate(new Date(dataInicio));
    setEndDate(new Date(dataFim));
  
    if (loja && accessToken) {
      try {
        let url;
        if (formaPagamento === "0") {
          // Endpoint para quando formaPagamento for "0"
          url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/fluxoCaixaTotal/${loja.id}/${dataInicio}/${dataFim}/0/1/0`;
        } else {
          // Endpoint para outros valores de formaPagamento
          url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotiposttsFormaPagamento/${loja.id}/${dataInicio}/${dataFim}/${type}/${tpAmb}/${status}/${formaPagamento}`;
        }
  
        const response = await axios.get(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        // Agora trata-se da mesma forma, seja qual for o valor de formaPagamento
        setData(response.data || []);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };
  
  

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Relatório de Caixa", 14, 10);
  
    let totalCaixas, totalValor;
  
    // Adicionando totais
    if (formaPagamento === "0") {
      // Para forma de pagamento "0", calcula a quantidade total de pedidos e o total de valor
      totalCaixas = data.reduce((acc, item) => acc + item.quantidadePedidos, 0);
      totalValor = data.reduce((acc, item) => acc + parseFloat(item.totalValor || 0), 0);
  
      // Exibindo os totais no PDF
      doc.text(`Quantidade de Pedidos: ${totalCaixas}`, 14, 20);
      doc.text(`Total Valor: R$ ${totalValor.toFixed(2).replace(".", ",")}`, 14, 30);
      doc.text(`Todos os Pagamentos`, 14, 40);
    } else {

      let formaPagamentoNome = "";

if (formaPagamento === "0") {
  formaPagamentoNome = "Todas";
} else if (formaPagamento === "01") {
  formaPagamentoNome = "Dinheiro";
} else if (formaPagamento === "02") {
  formaPagamentoNome = "Cheque";
} else if (formaPagamento === "03") {
  formaPagamentoNome = "Cartão de Crédito";
} else if (formaPagamento === "04") {
  formaPagamentoNome = "Cartão de Débito";
} else if (formaPagamento === "05") {
  formaPagamentoNome = "Crédito Loja";
} else if (formaPagamento === "10") {
  formaPagamentoNome = "Vale Alimentação";
} else if (formaPagamento === "11") {
  formaPagamentoNome = "Vale Refeição";
} else if (formaPagamento === "12") {
  formaPagamentoNome = "Vale Presente";
} else if (formaPagamento === "13") {
  formaPagamentoNome = "Vale Combustível";
} else if (formaPagamento === "15") {
  formaPagamentoNome = "Boleto bancário";
} else if (formaPagamento === "16") {
  formaPagamentoNome = "Depósito Bancário";
} else if (formaPagamento === "17") {
  formaPagamentoNome = "Pagamento Instantâneo (PIX)";
} else if (formaPagamento === "18") {
  formaPagamentoNome = "Transferência bancária, Carteira Digital";
} else if (formaPagamento === "19") {
  formaPagamentoNome = "Programa de fidelidade, Cashback, Crédito Virtual";
} else if (formaPagamento === "99") {
  formaPagamentoNome = "Outros";
}


      // Para outras formas de pagamento, calcula o total de caixas e valor
      totalCaixas = data.length;
      totalValor = data.reduce((acc, item) => acc + parseFloat(item.valor || 0), 0);
  
      // Exibindo os totais no PDF
      doc.text(`Total de Pedidos: ${totalCaixas}`, 14, 20);
      doc.text(`Total Valor: R$ ${totalValor.toFixed(2).replace(".", ",")}`, 14, 30);
      doc.text(`Pagamento: ${formaPagamentoNome}`, 14, 40);
    }
  
    // Gerando a tabela
    const tableData = data.map((item) => {
      if (formaPagamento === "0") {
        return [
          item.quantidadePedidos || "-",
          item.totalValor ? `R$ ${parseFloat(item.totalValor).toFixed(2).replace(".", ",")}` : "-",
          item.descricao || "-",
        ];
      } else {
        return [
          item.pedidoId?.slice(0, 4) || "-",
          item.dataHora ? new Date(item.dataHora).toLocaleString("pt-BR") : "-",
          `R$ ${parseFloat(item.valor).toFixed(2).replace(".", ",")}`,
        ];
      }
    });
  
    doc.autoTable({
      head: formaPagamento === "0"
        ? [["Quantidade de Pedidos", "Total Valor (R$)", "Descrição"]]
        : [["Pedido", "Data/Hora", "Valor (R$)"]],
      body: tableData,
      startY: 50, // Ajuste para posicionar a tabela
    });
  
    // Salvando o PDF
    doc.save("relatorio_caixa.pdf");
  };
  
  
  var totalValor;
  var totalCaixas
 
  if(formaPagamento === "0") {
    totalCaixas = data.reduce((acc, item) => acc + item.quantidadePedidos , 0);
     totalValor = data.reduce((acc, item) => acc + parseFloat(item.totalValor || 0), 0);
  }else{
    totalCaixas = data.length;
     totalValor = data.reduce((acc, item) => acc + parseFloat(item.valor || 0), 0);

  }


  let formaPagamentoNome1 = "";

  if (formaPagamento === "0") {
    formaPagamentoNome1 = "Todas";
  } else if (formaPagamento === "01") {
    formaPagamentoNome1 = "Dinheiro";
  } else if (formaPagamento === "02") {
    formaPagamentoNome1 = "Cheque";
  } else if (formaPagamento === "03") {
    formaPagamentoNome1 = "Cartão de Crédito";
  } else if (formaPagamento === "04") {
    formaPagamentoNome1 = "Cartão de Débito";
  } else if (formaPagamento === "05") {
    formaPagamentoNome1 = "Crédito Loja";
  } else if (formaPagamento === "10") {
    formaPagamentoNome1 = "Vale Alimentação";
  } else if (formaPagamento === "11") {
    formaPagamentoNome1 = "Vale Refeição";
  } else if (formaPagamento === "12") {
    formaPagamentoNome1 = "Vale Presente";
  } else if (formaPagamento === "13") {
    formaPagamentoNome1 = "Vale Combustível";
  } else if (formaPagamento === "15") {
    formaPagamentoNome1 = "Boleto bancário";
  } else if (formaPagamento === "16") {
    formaPagamentoNome1 = "Depósito Bancário";
  } else if (formaPagamento === "17") {
    formaPagamentoNome1 = "Pagamento Instantâneo (PIX)";
  } else if (formaPagamento === "18") {
    formaPagamentoNome1 = "Transferência bancária, Carteira Digital";
  } else if (formaPagamento === "19") {
    formaPagamentoNome1 = "Programa de fidelidade, Cashback, Crédito Virtual";
  } else if (formaPagamento === "99") {
    formaPagamentoNome1 = "Outros";
  }

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <h2>Relatório de Caixa - {formaPagamentoNome1}</h2>

      <div style={{ marginBottom: "20px", textAlign: "center" }}>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("hoje")}
          >
            Hoje
          </button>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("ontem")}
          >
            Ontem
          </button>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("semana")}
          >
            Semana
          </button>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <strong>Início:</strong>
              <input
                type="date"
                value={startDate.toISOString().split("T")[0]}
                onChange={(e) => setStartDate(new Date(e.target.value))}
                style={{ marginLeft: "5px", padding: "5px" }}
              />
            </div>
            <div>
              <strong>Fim:</strong>
              <input
                type="date"
                value={endDate.toISOString().split("T")[0]}
                onChange={(e) => setEndDate(new Date(e.target.value))}
                style={{ marginLeft: "5px", padding: "5px" }}
              />
            </div>
            <button
              style={{
                backgroundColor: loja?.id ? "black" : "grey",
                color: "#fff",
                padding: "10px 20px",
                cursor: loja?.id ? "pointer" : "not-allowed",
                border: "none",
                borderRadius: "5px",
                marginLeft: "10px",
              }}
              onClick={() => fetchData("custom")}
              disabled={!loja?.id}
            >
              Buscar
            </button>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div>
            <strong>Total de Pedidos:</strong> {totalCaixas}
          </div>

          <button
            style={{
              backgroundColor: "black",
              color: "#fff",
              padding: "5px 10px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={generatePDF}
          >
            Gerar PDF
          </button>


          <div>
            <strong>Total Valor:</strong> R$ {totalValor.toFixed(2).replace(".", ",")}
          </div>

        </div>

        {isLoading ? (
  <p style={{ textAlign: "center" }}>Carregando...</p>
) : (
  <div style={{ maxWidth: "100%", overflowX: "auto" }}>
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        marginTop: "20px",
        textAlign: "left",
      }}
    >
      <thead>
        <tr style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}>
          {formaPagamento === "0" ? (
            <>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>Quantidade de Pedidos</th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>Total Valor (R$)</th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>Descrição</th>
            </>
          ) : (
            <>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>Pedido</th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>Data/Hora</th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>Valor (R$)</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            {formaPagamento === "0" ? (
              <>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {item.quantidadePedidos || "-"}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {item.totalValor ? `R$ ${parseFloat(item.totalValor).toFixed(2).replace(".", ",")}` : "-"}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {item.descricao || "-"}
                </td>
              </>
            ) : (
              <>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {item.pedidoId?.slice(0, 4) || "-"}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {item.dataHora ? new Date(item.dataHora).toLocaleString("pt-BR") : "-"}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {`R$ ${parseFloat(item.valor).toFixed(2).replace(".", ",")}`}
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}


      </div>
    </div>
  );
};

export default RelatorioCaixa;
