import React, { useEffect, useState } from 'react';
import { Button, Typography, Card, Layout, Menu } from 'antd';
import { ArrowLeftOutlined, HomeOutlined, ShoppingCartOutlined, ToolOutlined, SettingOutlined, LogoutOutlined, CheckCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Header, Content, Footer } = Layout;



const ProdutosDevolucao = ({ itemSelecionado, setIsProdutosDevolucao }) => {
  const [selectedMenu, setSelectedMenu] = useState('inicio');





  const [loja, setLoja] = useState('');
  const idUser = window.localStorage.getItem("idUser");
  const accessToken = window.localStorage.getItem("accessToken");
  const tpAmb = window.localStorage.getItem('tpAmb');


  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(restaurante => {
          setLoja(restaurante.data);
        });
    }
  }, [idUser, accessToken]);

  const item = itemSelecionado;
  const [selectedItems, setSelectedItems] = useState([]);


  const [loading, setLoading] = useState(false);





  const toggleSelectedItem = (produto) => {
    setSelectedItems((prevSelectedItems) => {
      console.log("selecionado", produto);
      console.log("selectedItems", prevSelectedItems);

      const index = prevSelectedItems.findIndex((item) => item.id === produto.id);

      if (index === -1) {
        return [...prevSelectedItems, produto]; // Adiciona novo item
      } else {
        return prevSelectedItems.filter((item) => item.id !== produto.id); // Remove item se já existir
      }
    });
  };



  function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/) || cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

    if (match) {
      const intlCode = '+55 ';
      const part1 = match[1];
      const part2 = match[2];
      const part3 = match[3];

      return `(${part1}) ${part2}-${part3}`;
    }

    return null;
  }
  function getDataAtualComMinutos() {
    const now = new Date();

    const utcMinus3DateTime = new Date(now.getTime());

    // Formata a data e hora no formato desejado
    const formattedDateTime = utcMinus3DateTime.toLocaleString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });

    return formattedDateTime;
  }
  const enviarTodos = async () => {
    const novoItem = {
      loja: item.idEmpresa,
      vendedor: idUser,
      itens: item.itens,
      valorTotalAcrescimo: item.valorTotalAcrescimo,
      valorTotalDesconto: item.valorTotalDesconto,
      valorTotalBruto: item.valorTotalBruto,
      valorTotalLiquido: item.valorTotalLiquido,
      tpAmb: tpAmb,
      vFrete_ttlnfe: item.valorFrete ? item.valorFrete : 0
    };
    console.log(novoItem);
    if (accessToken && idUser) {
      let deuErro = false;

      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      const body = novoItem;
      console.log(
        `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/devolucao`,
      );
      const response = await axios
        .post(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/devolucao`,
          body,
          options,
        )

        .catch(error => {
          // Tratar erros aqui

          if (error.response) {
            console.log('Status de erro:', error.response.status);
            console.log('Dados de erro:', error.response.data);
            deuErro = true;
            window.alert('Erro ao gerar Devolução!' + error.response.status);
          }
        });

      if (deuErro) {
        setLoading(false);
        return;
      } else {
        console.log('Deu certo a requisição');
        const deuErro2 = false;
        let idEvento = response.data;
        console.log(idEvento);

        const body = {
          porcentagem: '100',
        };

        const response2 = await axios
          .post(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/voucher/porEvento/${idEvento}`,
            body,
            options,
          )

          .catch(error2 => {
            console.log('DEU ERRO');
            // Tratar erros aqui

            if (error2.response) {
              console.log('Status de erro:', error2.response.status);
              console.log('Dados de erro:', error2.response.data);
              deuErro2 = true;
              window.alert('Erro ao gerar Voucher!' + error2.response.status);
            }
          });

        if (deuErro2) {
          setLoading(false);
          return;
        } else {
          const htmlContent =
            `<!DOCTYPE html>
          <html lang="pt-BR">
          <head>
              <meta charset="UTF-8">
              <title>Cupom de Devolução</title>
              <style>
            body {
              font-family: monospace;
              font-size: 10pt;
              text-align: center;
            }
          </style>
          </head>
          <body>
              <div class="container">
                  <div class="header">
                      ${loja.nome}<br>
                      CNPJ: ${loja.cnpj}<br>
                      IE: ${loja.ie}<br>
                       ${loja.endereco.logradouro}, ${loja.endereco.numero},  ${loja.endereco.bairro},<br>
                      ${loja.endereco.cidade.nome} - ${loja.endereco.cidade.estado}<br>
                      ${loja.telefone ? formatPhoneNumber(loja.telefone) : ''}<br>
                  </div>
          
                  <div class="content">
                      ${getDataAtualComMinutos()}<br><br>
                      <strong>DEVOLUÇÃO ${response2.data.identificador ? response2.data.identificador : ''}</strong><br>
                  </div>
          
                  <div class="voucher">
                  <strong>*************************************</strong>
                  <br><strong>VOUCHER</strong><br>
                      ${response2.data.token ? response2.data.token.split('').join(' ') : ''}<br>
                      <strong>*************************************</strong><br>
                  </div>
          
                  <div class="footer">
                      VALOR ${response2.data.valor.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}<br>
                  </div>
              </div>
          </body>
          </html>`;
          console.log('Antes de gerar o html');
          console.log(htmlContent);
          try {
            const win = window.open("", "_blank");
            win.document.write(htmlContent);
            win.document.close();

            win.print();
            setIsProdutosDevolucao(false);


          } catch (error) {
            window.alert(
              'Erro ao Criar PDF',
              'Ocorreu um erro ao tentar criar o PDF: ' + error.message,
            );
          }
          window.alert(
            'Voucher no valor de ' +
            response2.data.valor.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }) +
            ' gerado com sucesso!',
          );
          //  navigation.navigate('Devolucao');
          console.log('DEU CERTO');
          console.log(response2.data);
        }
      }

      setLoading(false);
    }
    console.log(novoItem);
  };

  const enviarSelecionados = async () => {


    console.log(selectedItems);

    if (selectedItems.length == 0) {
      window.alert('Selecione ao menos um item para devolução!');
    } else {
      setLoading(true);

      let valorTotalAcrescimo = 0;
      let valorTotalDesconto = 0;
      let valorTotalBruto = 0;
      let valorTotalLiquido = 0;

      for (let i = 0; i < selectedItems.length; i++) {
        console.log(selectedItems[0].cfop);

        let valorTotalLiquidoAux = selectedItems[i].valorLiquidoUnitario;
        valorTotalLiquido += valorTotalLiquidoAux;

        let valorTotalAcrescimoAux = selectedItems[i].valorAcrescimoUnitario;
        valorTotalAcrescimo += valorTotalAcrescimoAux;

        let valorTotalBrutoAux = selectedItems[i].valorUnitario;
        valorTotalBruto += valorTotalBrutoAux;

        let valorTotalDescontoAux = selectedItems[i].valorDescontoUnitario;
        valorTotalDesconto += valorTotalDescontoAux;
      }

      valorTotalAcrescimo = valorTotalAcrescimo;
      valorTotalDesconto = valorTotalDesconto;
      valorTotalBruto = valorTotalBruto;
      valorTotalLiquido = valorTotalLiquido;

      const novoItem = {
        loja: item.idEmpresa,
        vendedor: idUser,
        itens: selectedItems,
        valorTotalAcrescimo: valorTotalAcrescimo,
        valorTotalDesconto: valorTotalDesconto,
        valorTotalBruto: valorTotalBruto,
        valorTotalLiquido: valorTotalLiquido,
        tpAmb: tpAmb,
        vFrete_ttlnfe: item.valorFrete ? item.valorFrete : 0
      };
      console.log(novoItem);

      if (accessToken && idUser) {
        let deuErro = false;

        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };

        const body = novoItem;
        console.log(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/devolucao`,
        );

        console.log(body);
        const response = await axios
          .post(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/devolucao`,
            body,
            options,
          )

          .catch(error => {
            // Tratar erros aqui

            if (error.response) {
              console.log('Status de erro:', error.response.status);
              console.log('Dados de erro:', error.response.data);
              deuErro = true;
              window.alert('Erro ao gerar Devolução!' + error.response.status);
            }
          });

        if (deuErro) {
          setLoading(false);
          return;
        } else {
          console.log('Deu certo a requisição');
          const deuErro2 = false;
          let idEvento = response.data;
          console.log(idEvento);

          const body = {
            porcentagem: '100',
          };

          const response2 = await axios
            .post(
              `https://techspdv-api-d3e739785e34.herokuapp.com/v1/voucher/porEvento/${idEvento}`,
              body,
              options,
            )

            .catch(error2 => {
              console.log('DEU ERRO');
              // Tratar erros aqui

              if (error2.response) {
                console.log('Status de erro:', error2.response.status);
                console.log('Dados de erro:', error2.response.data);
                deuErro2 = true;
                window.alert('Erro ao gerar Voucher!' + error2.response.status);
              }
            });

          if (deuErro2) {
            setLoading(false);
            return;
          } else {

            const htmlContent =
              `<!DOCTYPE html>
            <html lang="pt-BR">
            <head>
                <meta charset="UTF-8">
                <title>Cupom de Devolução</title>
                <style>
              body {
                font-family: monospace;
                font-size: 10pt;
                text-align: center;
              }
            </style>
            </head>
            <body>
                <div class="container">
                    <div class="header">
                        ${loja.nome}<br>
                        CNPJ: ${loja.cnpj}<br>
                        IE: ${loja.ie}<br>
                         ${loja.endereco.logradouro}, ${loja.endereco.numero},  ${loja.endereco.bairro},<br>
                        ${loja.endereco.cidade.nome} - ${loja.endereco.cidade.estado}<br>
                        ${loja.telefone ? formatPhoneNumber(loja.telefone) : ''}<br>
                    </div>
            
                    <div class="content">
                        ${getDataAtualComMinutos()}<br><br>
                        <strong>DEVOLUÇÃO ${response2.data.identificador ? response2.data.identificador : ''}</strong><br>
                    </div>
            
                    <div class="voucher">
                    <strong>*************************************</strong>
                    <br><strong>VOUCHER</strong><br>
                        ${response2.data.token ? response2.data.token.split('').join(' ') : ''}<br>
                        <strong>*************************************</strong><br>
                    </div>
            
                    <div class="footer">
                        VALOR ${response2.data.valor.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}<br>
                    </div>
                </div>
            </body>
            </html>`;



            try {

              const win = window.open("", "_blank");
              win.document.write(htmlContent);
              win.document.close();

              win.print();

              window.alert(
                'Voucher no valor de ' +
                response2.data.valor.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }) +
                ' gerado com sucesso!',
              );

              setIsProdutosDevolucao(false);
              console.log('DEU CERTO');
              console.log(response2.data);

            } catch (error) {
              console.log(error.message);
              window.alert(
                'Ocorreu um erro ao tentar criar o PDF: ' + error.message,
              );
            }





            // navigation.navigate('Devolucao');

          }
        }

        setLoading(false);
      }
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>


      <h1 style={{ textAlign: 'center', marginBottom: '5px', marginTop: 0 }}>Produtos Devolução</h1>

      <Content style={{ padding: '20px', textAlign: 'center' }}>
        <Button type="primary" style={{ marginRight: '10px', backgroundColor: 'black' }} onClick={enviarTodos} >Enviar Todos</Button>
        <Button type="primary" onClick={enviarSelecionados} style={{ backgroundColor: 'black' }} >Enviar Selecionados</Button>

        <div style={{ marginTop: '20px' }}>
        {item?.itens.map((produto, index) => {
          const isSelected = selectedItems.includes(produto);
          
          return (
            <Card
              key={index}
              style={{
                marginBottom: '15px',
                borderRadius: '10px',
                border: isSelected ? '2px solid limegreen' : '1px solid #ccc',
                position: 'relative',
                cursor: 'pointer'
              }}
              onClick={() => toggleSelectedItem(produto)}
            >
              {isSelected && (
                <CheckCircleOutlined
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    fontSize: 24,
                    color: 'limegreen'
                  }}
                />
              )}
              <Typography.Text><strong>GTIN:</strong> {produto.gtin}</Typography.Text><br />
              <Typography.Text><strong>CFOP:</strong> {produto.cfop}</Typography.Text><br />
              <Typography.Text><strong>Quantidade:</strong> {produto.quantidade}</Typography.Text><br />
              <Typography.Text><strong>Valor Unitário:</strong> R$ {produto.valorUnitario.toFixed(2)}</Typography.Text><br />
              <Typography.Text><strong>Valor Total:</strong> R$ {(produto.quantidade * produto.valorUnitario).toFixed(2)}</Typography.Text>
            </Card>
          );
        })}
      </div>
      </Content>
    </Layout>
  );
};

export default ProdutosDevolucao;
