import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import React, { useState } from 'react';
import BuscaDevolucao from './BuscaDevolucao';
import DevolucaoAvulsa from './devolucao/DevolucaoAvulsa';

const Devolucao = ({closeModal}) => {



  const [isBuscar, setIsBuscar] = useState(false);
  const [isAvulsa, setIsAvulsa] = useState(false);


  const handlePress = (operation) => {
    console.log(`Navigating to: ${operation}`);
    // Aqui você pode adicionar lógica de navegação, como usar React Router.
  };

  return (
    isBuscar ? <BuscaDevolucao></BuscaDevolucao>: isAvulsa ? <DevolucaoAvulsa setIsAvulsa={setIsAvulsa}></DevolucaoAvulsa> : (
      <div style={styles.container}>
        <div style={styles.containerButtons}>
          <Button
            style={{ ...styles.button, backgroundColor: '#841584' }}
            onClick={() =>{ setIsAvulsa(true); setIsBuscar(false); }}
          >
            <FontAwesomeIcon icon={faEdit} size="2x" />
            <span style={styles.buttonText}>Avulsa</span>
          </Button>

          <Button
            style={{ ...styles.button, backgroundColor: '#000' }}
            onClick={() => { setIsAvulsa(false); setIsBuscar(true); }}
          >
            <FontAwesomeIcon icon={faSearch} size="2x" />
            <span style={styles.buttonText}>Buscar</span>
          </Button>
        </div>
      </div>)
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '20px',
    paddingTop: '0',
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    width: '100px',
    height: '100px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none',
  },
  buttonText: {
    marginTop: '5px', // Para ajustar o texto em relação ao ícone
  },
};

export default Devolucao;
