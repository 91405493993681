import React, { useState, useEffect } from "react";
import axios from "axios";
import { startOfWeek } from "date-fns"; // Pacote para manipulação de datas
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RelatorioClientes = ({ status, type }) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const tpAmb = window.localStorage.getItem("tpAmb");
  const idUser = window.localStorage.getItem("idUser");

  const [loja, setLoja] = useState(null);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (accessToken && idUser) {
      axios
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => setLoja(response.data))
        .catch((error) => console.error("Erro ao buscar loja:", error));
    }
  }, [idUser, accessToken]);

  const fetchData = async (periodo) => {
    setIsLoading(true);

    let dataInicio;
    let dataFim;

    const currentDate = new Date();

    switch (periodo) {
      case "hoje":
        dataInicio = currentDate.toISOString().split("T")[0];
        dataFim = dataInicio;
        break;
      case "ontem":
        const ontem = new Date();
        ontem.setDate(ontem.getDate() - 1);
        dataInicio = ontem.toISOString().split("T")[0];
        dataFim = dataInicio;
        break;
      case "semana":
        dataInicio = startOfWeek(currentDate).toISOString().split("T")[0]; // Início da semana
        dataFim = currentDate.toISOString().split("T")[0]; // Data atual
        break;
      case "custom":
        dataInicio = startDate.toISOString().split("T")[0];
        dataFim = endDate.toISOString().split("T")[0];
        break;
      default:
        break;
    }

    setStartDate(new Date(dataInicio)); // Preenche o campo "Início"
    setEndDate(new Date(dataFim)); // Preenche o campo "Fim"

    if (loja && accessToken) {
      try {
        const response = await axios.get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotiposttsCliente/${loja.id}/${dataInicio}/${dataFim}/${type}/${tpAmb}/${status}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setData(response.data || []);
      } catch (error) {
        console.error("Erro ao buscar dados dos clientes:", error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Relatório de Clientes", 14, 10);

    const totalClientes = data.length;

    doc.text(`Total de Clientes: ${totalClientes}`, 14, 20);

    const tableData = data.map((item) => [
      item.nome || "-",
      item.tel || "-",
      item.ultimoPedido ? new Date(item.ultimoPedido).toLocaleString("pt-BR") : "-"
    ]);

    doc.autoTable({
      head: [["Nome", "Telefone", "Ultimo Pedido"]],
      body: tableData,
      startY: 30, // Posiciona a tabela após o total
    });

    doc.save("relatorio_clientes.pdf");
  };

  const totalClientes = data.length;

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <h2>Relatório de Clientes</h2>

      <div style={{ marginBottom: "20px", textAlign: "center" }}>
        {/* Botões para selecionar períodos e campos de data */}
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("hoje")}
          >
            Hoje
          </button>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("ontem")}
          >
            Ontem
          </button>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("semana")}
          >
            Semana
          </button>
          {/* Campos de data */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <strong>Início:</strong>
              <input
                type="date"
                value={startDate.toISOString().split("T")[0]}
                onChange={(e) => setStartDate(new Date(e.target.value))}
                style={{ marginLeft: "5px", padding: "5px" }}
              />
            </div>
            <div>
              <strong>Fim:</strong>
              <input
                type="date"
                value={endDate.toISOString().split("T")[0]}
                onChange={(e) => setEndDate(new Date(e.target.value))}
                style={{ marginLeft: "5px", padding: "5px" }}
              />
            </div>
            {/* Botão de Buscar */}
            <button
              style={{
                backgroundColor: loja?.id ? "black" : "grey",
                color: "#fff",
                padding: "10px 20px",
                cursor: loja?.id ? "pointer" : "not-allowed",
                border: "none",
                borderRadius: "5px",
                marginLeft: "10px",
              }}
              onClick={() => fetchData("custom")}
              disabled={!loja?.id}
            >
              Buscar
            </button>
            
          </div>
        </div>

        {/* Exibição do total de clientes lado a lado */}
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div>
            <strong>Total de Clientes:</strong> {totalClientes}
          </div>

          <button
            style={{
              backgroundColor: "black",
              color: "#fff",
              padding: "5px 10px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={generatePDF}
          >
            Gerar PDF
          </button>
        </div>

        {isLoading ? (
          <p style={{ textAlign: "center" }}>Carregando...</p>
        ) : (
          <div style={{ maxWidth: "100%", overflowX: "auto" }}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
                textAlign: "left",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}>
                  <th style={{ padding: "10px", border: "1px solid #ddd" }}>Nome</th>
                  <th style={{ padding: "10px", border: "1px solid #ddd" }}>Telefone</th>
                  <th style={{ padding: "10px", border: "1px solid #ddd" }}>Ultimo Pedido</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {item.nome || "-"}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {item.tel || "-"}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {item.ultimoPedido ? new Date(item.ultimoPedido).toLocaleString("pt-BR") : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default RelatorioClientes;
