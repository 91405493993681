import React, { useState, useEffect, useCallback } from 'react';
import {
  Input,
  Button,
  Modal,
  List,
  Card,
  Typography,
  Spin,
  message,
} from 'antd';
import { SearchOutlined, QrcodeOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import ProdutosDevolucao from './ProdutosDevolucao';

const { Text } = Typography;

const BuscaDevolucao = ({ onItemPress, closeModal }) => {

  const dispatch = useDispatch();
  const [isProdutosDevolucao, setIsProdutosDevolucao] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [termo, setTermo] = useState('hoje');
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [restauranteId, setRestauranteId] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setotTotalPages] = useState(0);
  const [buscaPorData, setBuscaPorData] = useState(false);
  const [showDateSelector, setShowDateSelector] = useState(false);
  // true para baixar e false para imprimir
  const [baixarImprimirPdf, setBaixarImprimirPdf] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [loja, setLoja] = useState('');
  const [itemSelecionado, setItemSelecionado] = useState(null);

  


  const idUser = window.localStorage.getItem("idUser");
  const accessToken = window.localStorage.getItem("accessToken");
  const tpAmb = window.localStorage.getItem('tpAmb');

  const [selectedItem, setSelectedItem] = useState(null);


  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(restaurante => {
          setLoja(restaurante.data);
        });
    }
  }, [idUser, accessToken]);

  const handleItemPress = async (item) => {
    console.log(item);
    //navigation.navigate('ProdutosDevolucao', {item});
  };

  const toggleDateSelector = () => {
    setShowDateSelector(!showDateSelector);
  };

  const toggleDateSelectorFalse = () => {
    setShowDateSelector(false);
  };

  const base64ToBytes = base64 => {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';

    base64 = base64.replace(/[^A-Za-z0-9+/=]/g, '');

    for (let i = 0; i < base64.length; i += 4) {
      const enc1 = chars.indexOf(base64.charAt(i));
      const enc2 = chars.indexOf(base64.charAt(i + 1));
      const enc3 = chars.indexOf(base64.charAt(i + 2));
      const enc4 = chars.indexOf(base64.charAt(i + 3));

      const chr1 = (enc1 << 2) | (enc2 >> 4);
      const chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      const chr3 = ((enc3 & 3) << 6) | enc4;

      output += String.fromCharCode(chr1);

      if (enc3 !== 64) {
        output += String.fromCharCode(chr2);
      }
      if (enc4 !== 64) {
        output += String.fromCharCode(chr3);
      }
    }

    return output;
  };

  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(restaurante => {
          setRestauranteId(restaurante.data.id);
        });
    }
  }, [idUser, accessToken]);

  const trataProdutos = async produtoLido => {
    console.log('ENTROU NA TRATA PRODUTO');
    await buscaNotas('hoje');
  };


  const handleGoBack = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const buscarPorCodChavCpf = async () => {
    if (accessToken && searchTerm !== null) {
      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };
        console.log('busca especifica');
       const response = await axios.get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/chavenumerotipo/${loja.id}/${searchTerm}/0/${tpAmb}`,
          options,
        );
        if (response.data._embedded?.PDVEvento.length > 0) {
          setProducts(response.data._embedded.PDVEvento);
        } else {
          setCurrentPage(0);
          setProducts([]);
        }
      } catch (error) {
        console.error('Erro ao carregar historico:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const loadCupons = async () => {
    if (accessToken && termo) {
      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };

        if (buscaPorData) {
          var response = '';
          console.log('loadCupons data()');
          const dataInicio = selectedStartDate.toISOString().split('T')[0];
          const dataFim = selectedEndDate.toISOString().split('T')[0];
          response = await axios.get(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipo/${
              loja.id
            }/${dataInicio}/${dataFim}/0/${tpAmb}?page=${currentPage + 1}`,
            options,
          );
        } else {
          console.log('loadCupons ()');
          response = await axios.get(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipo/${
              loja.id
            }/${termo}/0/${tpAmb}?page=${currentPage + 1}`,
            options,
          );
        }

        if (response.data._embedded?.cupomFiscal.length > 0) {
          setotTotalPages(response.data.page.totalPages);
          setCurrentPage(response.data.page.number);

          if (response.data.page.number !== 0) {
            console.log('adicionando nova pagina');
            setProducts([...products, ...response.data._embedded.PDVEvento]);
          } else {
            console.log('Adicionando array limpo');
            setProducts(response.data._embedded.PDVEvento);
          }
        } else {
          setCurrentPage(0);
          setProducts([]);
        }
      } catch (error) {
        console.error('Erro ao carregar historico:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const buscaNotas = async termo => {
    setBuscaPorData(false);
    setTermo(termo);
    setCurrentPage(0);

    if (accessToken) {
      console.log('dentro da busca');

      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };
        console.log('Buscar Notas ()');
        console.log(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipo/${loja.id}/${termo}/0/${tpAmb}?page=0`,
        );
        const response = await axios.get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipo/${loja.id}/${termo}/0/${tpAmb}?page=0`,
          options,
        );
        console.log(response.data._embedded);
        if (response.data._embedded.PDVEvento.length > 0) {
          setProducts(response.data._embedded.PDVEvento);
          setotTotalPages(response.data.page.totalPages);
          setCurrentPage(response.data.page.number);
        } else {
          setCurrentPage(0);
          setProducts([]);
        }
      } catch (error) {
        setProducts([]);
        console.error('Erro ao carregar cupomFiscal:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  function formatarValorEmReais(valor) {
    const simboloMoeda = 'R$';

    const valorFormatado = parseFloat(valor).toFixed(2).replace('.', ',');

    const partes = valorFormatado.split(',');
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return simboloMoeda + ' ' + partes.join(',');
  }
  function formatarCpfCnpj(cpfCnpj) {
    cpfCnpj = cpfCnpj.replace(/\D/g, '');

    if (cpfCnpj.length === 11) {
      return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cpfCnpj.length === 14) {
      return cpfCnpj.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      );
    } else {
      return cpfCnpj;
    }
  }
  const renderItem = (item) => {

console.log(item);

    /* const formattedDate = new Date(item.dataHoraFechamento).toLocaleDateString(
      'pt-BR',
    );*/
    const formattedDate = new Date(item.dataHoraFechamento).toLocaleString(
      'pt-BR',
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      },
    );

    return (
      <Card
        style={styles.card}
        disabled={loja.id ? false : true}
        onClick={() => {
          setIsProdutosDevolucao(true);
          setItemSelecionado(item);
        }} 
      >
        <span style={styles.cardText}>NF: {item.numeroDocFiscal}</span><br/>
        <span style={styles.cardText}>Série: {item.serieDocFiscal}</span><br/>
        <span style={styles.cardText}>Data: {`${formattedDate}`}</span><br/>
        <span style={styles.cardText}>
          Cliente: {item.cpf ? formatarCpfCnpj(item.cpf) : ''}
        </span><br/>
        <span style={styles.cardText}>
          Valor: {formatarValorEmReais(item.valorTotalLiquido)}
        </span>
      </Card>
    );
  };
  const handleLoadMore = () => {
    if (!isLoading && currentPage + 1 < totalPages) {
      loadCupons();
    }
  };

  return (
    isProdutosDevolucao?<ProdutosDevolucao itemSelecionado={itemSelecionado} setIsProdutosDevolucao={setIsProdutosDevolucao}></ProdutosDevolucao>:
    
    <div style={{ padding: '20px', backgroundColor: '#fff' }}>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        
        <Input
          placeholder="Pesquisar por EAN, Nome ou Código Interno"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          style={{backgroundColor:'black'}}
          onClick={()=>{
            setIsLoading(true);
            buscarPorCodChavCpf();
          }}
          disabled={!loja.id}
        >
          Buscar
        </Button>
      </div>


      {isLoading ? (
        <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        <List
          dataSource={products}
          renderItem={renderItem}
          onScroll={handleLoadMore}
          locale={{ emptyText: 'Nenhuma nota encontrada' }}
        />
      )}
    </div>
  );
};



const styles = {
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
    width: '100%',
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  input: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
    marginRight: 10,
    color: '#000',
  },
  card: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
  },
  cardText: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 5,
    color: '#000',
  },
  emptyListText: {
    textAlign: 'center',
    marginTop: 20,
    fontSize: 16,
    color: '#000',
  },
  searchButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#000',
    padding: 14,
    borderRadius: 5,
  },
  searchButtonText: {
    color: '#fff',
    marginLeft: 10, 
  },

  selectButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#000', 
    padding: 14,
    borderRadius: 5,
  },

  selectButton2: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#000', 
    padding: 14,
    borderRadius: 5,
    marginBottom: 5,
  },

  baseText: {
    fontWeight: 'bold',
    color: '#000',
    fontSize: 30,
  },

  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  button: {
    backgroundColor: '#028ddb',
    padding: 8,
    borderRadius: 5,
    width: '24%', // Ajuste a largura conforme necessário
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: 13,
  },
};



export default BuscaDevolucao;
